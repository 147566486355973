import React, { useState } from "react"
import { Button, Modal } from "antd"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ContactForm from "../components/ContactForm"
import ImageGallery from "../components/ImageGallery"

const Bagues = () => {
//Hooks
const [dataContact, setDataContact] = useState(false)

//Modal
const [showModalContact, setShowModalContact] = useState(false)

const showModalContactForm = (value_i) => {
  setShowModalContact(true)
  setDataContact(value_i)
}

const handleContactFormOk = e => {
  setShowModalContact(false)
}

const handleContactFormCancel = e => {
  setShowModalContact(false)
}

return (
  <>
  <Layout>
    <SEO title="Bagues" />

    <div className="slideshow">
      <div className="usp">
        <div className="usp-txt-header">Bagues</div>
        <div  className="usp-txt">Nous réalisons vos rêves !</div>
      </div>
    </div>
    <div className="border-gold-bottom" />

    <h2 className="h2-mobile"  style={{ marginTop: 40 }}>Bagues</h2>



    <h3 className="h3-mobile">Nous réalisons vos rêves !</h3>
    <p className="p-2 p-font" style={{textAlign: 'center'}}>Voici quelques créations élaborées par nos soins.</p>

    <section>
      <ImageGallery id="bagues" />

      <div style={{display: 'flex', justifyContent: 'center'}}><Button className="button-gold-box btn-horlogeries" onClick={ () => showModalContactForm('Bagues') }>Nous contacter</Button></div>
      <Modal title="Formulaire de contact"
        style={{minWidth: '40vw', height: '80vh'}}
        visible={ showModalContact }
        onOk={ handleContactFormOk }
        onCancel={ handleContactFormCancel }
        okButtonProps={{ style: { display: 'none' } }}
      >

        <ContactForm page={dataContact} />

      </Modal>

    </section>

  </Layout>
  </>
)
}

export default Bagues
